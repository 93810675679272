import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "#e5eef5",
      main: "#00559e",
      dark: "#00559e",
    },
  },
});

export default theme;
