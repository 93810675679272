// Core Packages
import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Material
import CircularProgress from "@material-ui/core/CircularProgress";

// Utils
import { getItem } from "../services/storage";
import { USER_ID } from "../utils/constants";
import { routes } from "./constants";

// Styles
import styles from "./style.module.css";

// Custom Components
const Auth = lazy(() => import("../pages/auth"));
const Home = lazy(() => import("../pages/home"));
const NotFound = lazy(() => import("../pages/NotFound"));

const Loader = () => (
  <div className={styles.loader}>
    <CircularProgress />
  </div>
);

// const isAuthenticated = () => getItem(USER_ID);

// const AuthContainer = () => isAuthenticated() ? <Redirect to={routes.HOME} /> : <Auth />;

function AuthContainer() {
  const isAuthenticated = getItem(USER_ID);

  return isAuthenticated ? <Redirect to={routes.HOME} /> : <Auth />;
}

function HomeContainer() {
  const isAuthenticated = getItem(USER_ID);

  return isAuthenticated ? <Home /> : <Redirect to={routes.AUTH} />;
}

export default function Routes() {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path={routes.HOME}>
          <HomeContainer />
        </Route>
        <Route path={routes.AUTH}>
          <AuthContainer />
        </Route>
        <Redirect path="/" to={routes.AUTH} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
}
