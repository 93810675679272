// Core Packages
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { ApolloProvider } from "@apollo/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import reportWebVitals from "./reportWebVitals";
import { getEnvDetails } from "./utils/helpers";
import theme from "./theme";
import client from "./graphql";
import App from "./App";
import "./firebase";
import "./sentry";

// Styles
import "./index.css";
import "./global.css";
import "./overrides.css";

const root = document.getElementById("root");
const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 20,
      cacheTime: 30,
      refetchOnMount: "always",
      refetchOnWindowFocus: "always",
      refetchOnReconnect: "always",
    },
    mutations: {
      retry: 2,
    },
  },
};
const queryClient = new QueryClient(queryClientConfig);

ReactDOM.render(
  <ApolloProvider client={client}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  </ApolloProvider>,
  root
);

const { isDev } = getEnvDetails();
if (isDev) {
  reportWebVitals();
}
