// Core Packages
import { BrowserRouter } from "react-router-dom";

// Utils
import Routes from "./routes";

// Toast
import { Toaster } from "react-hot-toast";

import { defaults } from "chart.js";

defaults.font.family = "Gilmer"; //default font for chart

function App() {
  return (
    <BrowserRouter>
      <Routes />
      <Toaster position="top-right" />
    </BrowserRouter>
  );
}

export default App;
